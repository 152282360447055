@font-face {
  font-family: 'Aktiv Grotesk';
  src: url('/fonts/AktivGrotesk_Blk.woff2') format('woff2'), url('../fonts/AktivGrotesk_Blk.woff') format('woff'),
    url('../fonts/AktivGrotesk_Blk.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-thin-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-thin-webfont.woff') format('woff'), url('/fonts/proximanova-thin.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-thinit-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-thinit-webfont.woff') format('woff'),
    url('/fonts/proximanova-thinit.otf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-light-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-light-webfont.woff') format('woff'), url('/fonts/proximanova-light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-lightit-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-lightit-webfont.woff') format('woff'),
    url('/fonts/proximanova-lightit.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-regular-webfont.woff') format('woff'),
    url('/fonts/proximanova-regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-regularit-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-regularit-webfont.woff') format('woff'),
    url('/fonts/proximanova-regularit.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-medium-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-medium-webfont.woff') format('woff'),
    url('/fonts/proximanova-medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-mediumit-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-mediumit-webfont.woff') format('woff'),
    url('/fonts/proximanova-mediumit.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-semibold-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-semibold-webfont.woff') format('woff'),
    url('/fonts/proximanova-semibold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-semiboldit-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-semiboldit-webfont.woff') format('woff'),
    url('/fonts/proximanova-semiboldit.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-bold-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-bold-webfont.woff') format('woff'), url('/fonts/proximanova-bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-boldit-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-boldit-webfont.woff') format('woff'),
    url('/fonts/proximanova-boldit.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-extrabold-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-extrabold-webfont.woff') format('woff'),
    url('/fonts/proximanova-extrabold.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-extraboldit-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-extraboldit-webfont.woff') format('woff'),
    url('/fonts/proximanova-extraboldit.otf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-black-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-black-webfont.woff') format('woff'), url('/fonts/proximanova-black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proximanova-blackit-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-blackit-webfont.woff') format('woff'),
    url('/fonts/proximanova-blackit.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

* {
  font-family: 'Proxima Nova', 'sans-serif';
}

#__next {
  height: 100%;
}

.drift-frame-controller {
  z-index: 10 !important;
  bottom: 8px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
